import * as _queryString2 from "query-string";
var _queryString = "default" in _queryString2 ? _queryString2.default : _queryString2;
import * as _zustand2 from "zustand";
var _zustand = "default" in _zustand2 ? _zustand2.default : _zustand2;
import * as _middleware2 from "zustand/middleware";
var _middleware = "default" in _middleware2 ? _middleware2.default : _middleware2;
import * as _react2 from "react";
var _react = "default" in _react2 ? _react2.default : _react2;
var exports = {};
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all) __defProp(target, name, {
    get: all[name],
    enumerable: true
  });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from)) if (!__hasOwnProp.call(to, key) && key !== except) __defProp(to, key, {
      get: () => from[key],
      enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable
    });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", {
  value: mod,
  enumerable: true
}) : target, mod));
var __toCommonJS = mod => __copyProps(__defProp({}, "__esModule", {
  value: true
}), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  DEFAUlT_PRESET: () => DEFAUlT_PRESET,
  PRESETS: () => PRESETS,
  initialActivePreset: () => initialActivePreset,
  updateGradientState: () => updateGradientState,
  useCursorStore: () => useCursorStore,
  useDomStore: () => useDomStore,
  usePropertyStore: () => usePropertyStore,
  useQueryState: () => useQueryState,
  useQueryStore: () => useQueryStore,
  useUIStore: () => useUIStore,
  useURLQueryState: () => useURLQueryState
});
exports = __toCommonJS(src_exports);

// src/store.ts
var qs = __toESM(_queryString);
var import_zustand = __toESM(_zustand);
var import_middleware = _middleware;

// src/presets.ts
var initialActivePreset = 0;
var DEFAUlT_PRESET = "?pixelDensity=1&fov=45";
var PRESETS = [{
  title: "Halo",
  color: "white",
  url: "?animate=on&axesHelper=off&brightness=1.2&cAzimuthAngle=180&cDistance=3.6&cPolarAngle=90&cameraZoom=1&color1=%23ff5005&color2=%23dbba95&color3=%23d0bce1&embedMode=off&envPreset=city&gizmoHelper=hide&grain=on&lightType=3d&pixelDensity=1&fov=45&positionX=-1.4&positionY=0&positionZ=0&reflection=0.1&rotationX=0&rotationY=10&rotationZ=50&shader=defaults&type=plane&uDensity=1.3&uSpeed=0.4&uStrength=4&uTime=0&uFrequency=5.5&wireframe=false"
}, {
  title: "Pensive",
  color: "white",
  url: "?animate=on&axesHelper=off&brightness=1.5&cAzimuthAngle=250&cDistance=1.5&cPolarAngle=140&cameraZoom=12.5&color1=%23809bd6&color2=%23910aff&color3=%23af38ff&embedMode=off&envPreset=city&gizmoHelper=hide&grain=on&lightType=3d&pixelDensity=1&fov=45&positionX=0&positionY=0&positionZ=0&reflection=0.5&rotationX=0&rotationY=0&rotationZ=140&shader=defaults&type=sphere&uAmplitude=7&uDensity=0.8&uFrequency=5.5&uSpeed=0.3&uStrength=0.4&uTime=0&wireframe=false"
}, {
  title: "Mint",
  color: "white",
  url: "?animate=on&axesHelper=off&brightness=1.2&cAzimuthAngle=170&cDistance=4.4&cPolarAngle=70&cameraZoom=1&color1=%2394ffd1&color2=%236bf5ff&color3=%23ffffff&embedMode=off&envPreset=city&gizmoHelper=hide&grain=off&lightType=3d&pixelDensity=1&fov=45&positionX=0&positionY=0.9&positionZ=-0.3&reflection=0.1&rotationX=45&rotationY=0&rotationZ=0&type=waterPlane&uAmplitude=0&uDensity=1.2&uFrequency=0&uSpeed=0.2&uStrength=3.4&uTime=0&wireframe=false&shader=defaults"
}, {
  title: "Interstella",
  color: "white",
  url: "?animate=on&axesHelper=off&brightness=0.8&cAzimuthAngle=270&cDistance=0.5&cPolarAngle=180&cameraZoom=15.1&color1=%2373bfc4&color2=%23ff810a&color3=%238da0ce&embedMode=off&envPreset=city&gizmoHelper=hide&grain=on&lightType=env&pixelDensity=1&fov=45&positionX=-0.1&positionY=0&positionZ=0&reflection=0.4&rotationX=0&rotationY=130&rotationZ=70&shader=defaults&type=sphere&uAmplitude=3.2&uDensity=0.8&uFrequency=5.5&uSpeed=0.3&uStrength=0.3&uTime=0&wireframe=false"
}, {
  title: "Nighty night",
  color: "white",
  url: "?animate=on&axesHelper=off&brightness=1&cAzimuthAngle=180&cDistance=2.8&cPolarAngle=80&cameraZoom=9.1&color1=%23606080&color2=%238d7dca&color3=%23212121&embedMode=off&envPreset=city&gizmoHelper=hide&grain=on&lightType=3d&pixelDensity=1&fov=45&positionX=0&positionY=0&positionZ=0&reflection=0.1&rotationX=50&rotationY=0&rotationZ=-60&shader=defaults&type=waterPlane&uAmplitude=0&uDensity=1.5&uFrequency=0&uSpeed=0.3&uStrength=1.5&uTime=8&wireframe=false"
}, {
  title: "Viola orientalis",
  color: "white",
  url: "?animate=on&axesHelper=on&brightness=1.1&cAzimuthAngle=0&cDistance=7.1&cPolarAngle=140&cameraZoom=17.3&color1=%23ffffff&color2=%23ffbb00&color3=%230700ff&embedMode=off&envPreset=city&grain=off&lightType=3d&pixelDensity=1&fov=45&positionX=0&positionY=0&positionZ=0&reflection=0.1&rotationX=0&rotationY=0&rotationZ=0&type=sphere&uAmplitude=1.4&uDensity=1.1&uSpeed=0.1&uStrength=1&uTime=0&wireframe=false&uFrequency=5.5&shader=defaults"
}, {
  title: "Universe",
  color: "white",
  url: "?animate=on&axesHelper=on&brightness=1.1&cAzimuthAngle=180&cDistance=3.9&cPolarAngle=115&cameraZoom=1&color1=%235606FF&color2=%23FE8989&color3=%23000000&embedMode=off&envPreset=city&grain=off&lightType=3d&pixelDensity=1&fov=45&positionX=-0.5&positionY=0.1&positionZ=0&reflection=0.1&rotationX=0&rotationY=0&rotationZ=235&type=waterPlane&uAmplitude=0&uDensity=1.1&uSpeed=0.1&uStrength=2.4&uTime=0.2&uFrequency=5.5&wireframe=false&shader=defaults"
}, {
  title: "Sunset",
  color: "white",
  url: "?animate=on&axesHelper=on&bgColor1=%23000000&bgColor2=%23000000&brightness=1.5&cAzimuthAngle=60&cDistance=7.1&cPolarAngle=90&cameraZoom=15.3&color1=%23ff7a33&color2=%2333a0ff&color3=%23ffc53d&embedMode=off&envPreset=dawn&grain=off&http%3A%2F%2Flocalhost%3A3002%2Fcustomize%3Fanimate=on&lightType=3d&pixelDensity=1&fov=45&positionX=0&positionY=-0.15&positionZ=0&reflection=0.1&rotationX=0&rotationY=0&rotationZ=0&shader=defaults&type=sphere&uAmplitude=1.4&uDensity=1.1&uSpeed=0.1&uStrength=0.4&uTime=0&uFrequency=5.5&wireframe=false"
}, {
  title: "Mandarin",
  color: "white",
  url: "?animate=on&axesHelper=on&bgColor1=%23000000&bgColor2=%23000000&brightness=1.2&cAzimuthAngle=180&cDistance=2.4&cPolarAngle=95&cameraZoom=1&color1=%23ff6a1a&color2=%23c73c00&color3=%23FD4912&embedMode=off&envPreset=city&grain=off&lightType=3d&pixelDensity=1&fov=45&positionX=0&positionY=-2.1&positionZ=0&reflection=0.1&rotationX=0&rotationY=0&rotationZ=225&shader=defaults&type=waterPlane&uAmplitude=0&uFrequency=5.5&uDensity=1.8&uSpeed=0.2&uStrength=3&uTime=0.2&wireframe=false"
}, {
  title: "Cotton Candy",
  color: "white",
  url: "?animate=on&axesHelper=off&brightness=1.2&cAzimuthAngle=180&cDistance=2.9&cPolarAngle=120&cameraZoom=1&color1=%23ebedff&color2=%23f3f2f8&color3=%23dbf8ff&embedMode=off&envPreset=city&gizmoHelper=hide&grain=off&lightType=3d&pixelDensity=1&fov=45&positionX=0&positionY=1.8&positionZ=0&reflection=0.1&rotationX=0&rotationY=0&rotationZ=-90&type=waterPlane&uDensity=1&uSpeed=0.3&uStrength=3&uTime=0.2&uFrequency=5.5&wireframe=false&shader=defaults"
}];

// src/store.ts
var useQueryStore = (0, import_zustand.default)(set => __spreadValues({}, parseState()));
var usePropertyStore = (0, import_zustand.default)(set => ({
  hoverState: 0,
  inAbout: false
}));
var useCursorStore = (0, import_zustand.default)(() => {
  return {
    hover: "default"
  };
});
var updateGradientState = querystate => {
  const state = parseState(querystate);
  useQueryStore.setState(state, true);
};
function parseState(search = DEFAUlT_PRESET) {
  return qs.parse(search, {
    parseNumbers: true,
    parseBooleans: true,
    arrayFormat: "index"
  });
}
var useDomStore = (0, import_zustand.default)(() => {
  return {
    dom: null
  };
});
var useUIStore = (0, import_zustand.default)((0, import_middleware.combine)({
  activePreset: initialActivePreset,
  mode: "full",
  loadingPercentage: 0
}, set => ({
  setActivePreset: by => set(state => ({
    activePreset: by
  })),
  setMode: data => set(state => __spreadProps(__spreadValues({}, state), {
    mode: data
  })),
  setLoadingPercentage: data => set(state => __spreadProps(__spreadValues({}, state), {
    loadingPercentage: data
  }))
})));

// src/useQueryState.ts
var import_react = _react;
var qs2 = __toESM(_queryString);
var useQueryState = (propName, defaultValue = null) => {
  const selector = (0, import_react.useCallback)(state => typeof state[propName] !== "undefined" ? state[propName] : defaultValue, [propName, defaultValue]);
  const globalValue = useQueryStore(selector);
  const _setGlobalValue = (0, import_react.useCallback)(valueFun => useQueryStore.setState({
    [propName]: valueFun(useQueryStore.getState()[propName])
  }), [propName]);
  const setQueryValue = (0, import_react.useCallback)(newVal => {
    _setGlobalValue(currentState => {
      if (typeof newVal === "function") {
        newVal = newVal(currentState || defaultValue);
      }
      if (Number.isFinite(newVal)) {
        newVal = parseFloat(newVal.toFixed(2));
      }
      setTimeout(() => {
        const query = useQueryStore.getState();
        updateHistory(qs2.stringifyUrl({
          url: window.location.pathname,
          query
        }, {
          skipNull: true,
          arrayFormat: "index"
        }));
      }, 0);
      return newVal;
    });
  }, [_setGlobalValue]);
  return [globalValue, setQueryValue];
};
var useURLQueryState = () => {
  const setQueryValue = search => {
    setTimeout(() => {
      const query = useQueryStore.getState();
      updateHistory(qs2.stringifyUrl({
        url: window.location.pathname,
        query
      }, {
        skipNull: true,
        arrayFormat: "index"
      }));
    }, 0);
    const state = qs2.parse(search, {
      parseNumbers: true,
      parseBooleans: true,
      arrayFormat: "index"
    });
    useQueryStore.setState(state);
  };
  return setQueryValue;
};
function updateHistory(path) {
  var _a;
  window.history.pushState({
    prevUrls: [...(((_a = window.history.state) == null ? void 0 : _a.prevUrls) || []), window.location.origin + path]
  }, document.title, path);
}
// Annotate the CommonJS export names for ESM import in node:
0 && (exports = {
  DEFAUlT_PRESET,
  PRESETS,
  initialActivePreset,
  updateGradientState,
  useCursorStore,
  useDomStore,
  usePropertyStore,
  useQueryState,
  useQueryStore,
  useUIStore,
  useURLQueryState
});
export default exports;
const _DEFAUlT_PRESET = exports.DEFAUlT_PRESET,
  _PRESETS = exports.PRESETS,
  _initialActivePreset = exports.initialActivePreset,
  _updateGradientState = exports.updateGradientState,
  _useCursorStore = exports.useCursorStore,
  _useDomStore = exports.useDomStore,
  _usePropertyStore = exports.usePropertyStore,
  _useQueryState = exports.useQueryState,
  _useQueryStore = exports.useQueryStore,
  _useUIStore = exports.useUIStore,
  _useURLQueryState = exports.useURLQueryState;
export { _DEFAUlT_PRESET as DEFAUlT_PRESET, _PRESETS as PRESETS, _initialActivePreset as initialActivePreset, _updateGradientState as updateGradientState, _useCursorStore as useCursorStore, _useDomStore as useDomStore, _usePropertyStore as usePropertyStore, _useQueryState as useQueryState, _useQueryStore as useQueryStore, _useUIStore as useUIStore, _useURLQueryState as useURLQueryState };